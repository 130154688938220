angular.module('fingerink')
    .factory('productService', ['$http', function ($http) {
        var sdo = {

            getProducts: function (params) {
                return $http({
                    method: 'GET',
                    url: 'products' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            },

            getProductsPrices: function (params) {
                return $http({
                    method: 'GET',
                    url: 'products/prices' + ((params != null) ? '?' + decodeURIComponent($.param(params)) : ""),
                    crossDomain: true
                });
            }


        };
        return sdo;
}]);
